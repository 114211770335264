import { Links, Scripts, LiveReload, AdminPanel, LosseLink } from '@ubo/losse-sjedel'
import Logo from '../elements/Logo'

export default function PageNotFound() {
  return (
    <html lang="nl">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Links />
      </head>
      <body className="h-screen">
        <div className="min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="mx-auto max-w-max">
            <div className="mb-20 flex justify-center">
              <Logo className="text-blitz-shark w-[125px] h-[51px] lg:w-[152px] lg:h-[62px]" />
            </div>
            <main className="text-proxima-nova sm:flex">
              <p className="text-4xl font-extrabold sm:text-6xl">404</p>
              <div className="sm:ml-6">
                <div className="sm:border-l-2 sm:pl-6">
                  <h1 className="text-4xl font-extrabold tracking-tight sm:text-4xl">Pagina niet gevonden</h1>
                  <p className="mt-1 text-black/50">Controleer de URL in de adresbalk en probeer het opnieuw.</p>
                </div>
                <div className="mt-10 flex max-sm:flex-col gap-4 sm:border-l sm:border-transparent sm:pl-6">
                  <LosseLink to="/" className="btn max-sm:w-full">
                    Ga naar home
                  </LosseLink>
                  <LosseLink to="/contact/" className="btn btn--alt max-sm:w-full">
                    Contact
                  </LosseLink>
                </div>
              </div>
            </main>
          </div>
        </div>
        <Scripts />
        <LiveReload />
        <AdminPanel />
      </body>
    </html>
  )
}
